const ROOT_PATH = '';
const NOT_MATCHED_PATH = '**';

// -----ROUTES FOR ROUTING MODULES---- //
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGRoutes {
  export const APP_ROUTES = {
    ROOT: ROOT_PATH,
    DASHBOARD_WES: 'dashboard/wes',
    DASHBOARD_D2C: 'dashboard/d2c',
    DASHBOARD_B2B: 'dashboard/b2b',
    NOT_MATCHED: NOT_MATCHED_PATH,
  };

  export const WES_FORECAST_ROUTES = {
    ROOT: ROOT_PATH,
    UPLOAD: 'wes/upload',
    BROWSE_DATA: 'wes/browse-data',
    FORECAST_ADJUSTMENT: 'wes/forecast-adjustment',
    FORECAST_BULK_ADJUSTMENT: 'wes/forecast-bulk-adjustment',
    ADJUSTMENT_ANALYSIS: 'wes/adjustment-analysis',
  };

  export const D2C_FORECAST_ROUTES = {
    ROOT: ROOT_PATH,
    BROWSE_DATA: 'd2c/browse-data',
    FORECAST_ADJUSTMENT_WEEK: 'd2c/forecast-adjustment/week',
    FORECAST_ADJUSTMENT_RANGE: 'd2c/forecast-adjustment/range',
    FORECAST_BULK_ADJUSTMENT: 'd2c/forecast-bulk-adjustment',
    FORECAST_BULK_ADJUSTMENT_RANGE: 'd2c/forecast-bulk-adjustment/range',
    REGIONS_TO_WAREHOUSES: 'd2c/regions-to-warehouses',
    FORECAST_V2: 'd2c/forecast-v2',
  };

  export const B2B_FORECAST_ROUTES = {
    ROOT: ROOT_PATH,
    BROWSE_DATA: 'b2b/browse-data',
    ADJUST: 'b2b/adjust',
    FORECAST_ADJUSTMENT: 'b2b/forecast-adjustment',
  };

  export const BUDGET_FORECAST_ROUTES = {
    ROOT: ROOT_PATH,
    FREEZE: 'budget/freeze',
  };

  export const ROLES_ROUTES = {
    ROOT: ROOT_PATH,
    ROLES: 'roles',
    ROLE: 'roles/:id',
    NEW: 'roles/new',
  };

  export const VERSIONS_ROUTES = {
    ROOT: ROOT_PATH,
    VERSIONS: 'versions',
  };

  export const DIVISIONS_ROUTES = {
    ROOT: ROOT_PATH,
    DIVISIONS: 'divisions',
  };

  export const VOYAGER_ROUTES = {
    ROOT: ROOT_PATH,
    VOYAGER: 'voyager',
  };

  export const SETTINGS_ROUTES = {
    ROOT: ROOT_PATH,
    DIVISIONS: 'divisions',
    VOYAGER: 'voyager',
    REGION_WAREHOUSE_WEBSITE: 'region-warehouse-website',
  };

  export const EVENTS_ROUTES = {
    ROOT: ROOT_PATH,
    EVENTS: 'events',
  };
}

// -----PATH FROM APPLICATION ROOT TO USE BY NAVIGATION---- //
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGAppPath {
  export const ROOT = `/${THGRoutes.APP_ROUTES.ROOT}`;
  export const DASHBOARD_WES = `/${THGRoutes.APP_ROUTES.DASHBOARD_WES}`;
  export const DASHBOARD_D2C = `/${THGRoutes.APP_ROUTES.DASHBOARD_D2C}`;
  export const DASHBOARD_B2B = `/${THGRoutes.APP_ROUTES.DASHBOARD_B2B}`;
  export const NOT_MATCHED = `/${THGRoutes.APP_ROUTES.NOT_MATCHED}`;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGWESForecastPath {
  export const UPLOAD = `/${THGRoutes.WES_FORECAST_ROUTES.UPLOAD}`;
  export const BROWSE_DATA = `/${THGRoutes.WES_FORECAST_ROUTES.BROWSE_DATA}`;
  export const FORECAST_ADJUSTMENT = `/${THGRoutes.WES_FORECAST_ROUTES.FORECAST_ADJUSTMENT}`;
  export const ADJUSTMENT_ANALYSIS = `/${THGRoutes.WES_FORECAST_ROUTES.ADJUSTMENT_ANALYSIS}`;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGD2CForecastPath {
  export const REGIONS_TO_WAREHOUSES = `/${THGRoutes.D2C_FORECAST_ROUTES.REGIONS_TO_WAREHOUSES}`;
  export const BROWSE_DATA = `/${THGRoutes.D2C_FORECAST_ROUTES.BROWSE_DATA}`;
  export const FORECAST_ADJUSTMENT_WEEK = `/${THGRoutes.D2C_FORECAST_ROUTES.FORECAST_ADJUSTMENT_WEEK}`;
  export const FORECAST_ADJUSTMENT_RANGE = `/${THGRoutes.D2C_FORECAST_ROUTES.FORECAST_ADJUSTMENT_RANGE}`;
  export const FORECAST_V2 = `/${THGRoutes.D2C_FORECAST_ROUTES.FORECAST_V2}`;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGB2BForecastPath {
  export const BROWSE_DATA = `/${THGRoutes.B2B_FORECAST_ROUTES.BROWSE_DATA}`;
  export const FORECAST_ADJUSTMENT = `/${THGRoutes.B2B_FORECAST_ROUTES.FORECAST_ADJUSTMENT}`;
}

export namespace THGBudgetForecastPath {
  export const BUDGET_FREEZE = `/${THGRoutes.BUDGET_FORECAST_ROUTES.FREEZE}`;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGRolesPath {
  export const ROLES = `/${THGRoutes.ROLES_ROUTES.ROLES}`;
  export const NEW = `/${THGRoutes.ROLES_ROUTES.NEW}`;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace THGConfigPath {
  export const DIVISIONS = `/${THGRoutes.DIVISIONS_ROUTES.DIVISIONS}`;
  export const VERSIONS = `/${THGRoutes.VERSIONS_ROUTES.VERSIONS}`;
  export const VOYAGER = `/${THGRoutes.VOYAGER_ROUTES.VOYAGER}`;
  export const REGION_WAREHOUSE_WEBSITE = `/${THGRoutes.SETTINGS_ROUTES.REGION_WAREHOUSE_WEBSITE}`;
}

export namespace THGEventsPath {
  export const EVENTS = `/${THGRoutes.EVENTS_ROUTES.EVENTS}`;
}
