import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { THGRoutes } from './core/domain/routing.config';
import { AdjustmentAnalysisModule } from './feature/adjustment-analysis/adjustment-analysis.module';
import { D2CBrowseDataModule } from './feature/d2c-browse-data/d2c-browse-data.module';
import { ForecastCsvUploadModule } from './feature/forecast-csv-upload/forecast-csv-upload.module';
import { WESBrowseDataModule } from './feature/wes-browse-data/wes-browse-data.module';
import { WESForecastAdjustmentModule } from './feature/wes-forecast-adjustment/wes-forecast-adjustment.module';

import { PermissionLevel } from '@thg-procure-team/procure-common-ui/business/user';
import { PermissionsGuard } from '@thg-procure-team/procure-common-ui/platform/security';

import { B2BBrowseDataModule } from '@demand-forecast/feature/b2b-browse-data/b2b-browse-data.module';
import { B2BForecastAdjustmentModule } from '@demand-forecast/feature/b2b-forecast-adjustment/b2b-forecast-adjustment.module';
import { BudgetFreezeModule } from '@demand-forecast/feature/budget-freeze/budget-freeze.module';
import { D2CForecastAdjustmentRangeModule } from '@demand-forecast/feature/d2c-forecast-adjustment-range/d2c-forecast-adjustment-range.module';
import { D2CForecastAdjustmentModule } from '@demand-forecast/feature/d2c-forecast-adjustment/d2c-forecast-adjustment.module';
import { D2cForecastV2Module } from '@demand-forecast/feature/d2c-forecast-v2/d2c-forecast-v2.module';
import { DashboardReportsModule } from '@demand-forecast/feature/dashboard-reports/dashboard-reports.module';
import { B2BDashboardModule } from '@demand-forecast/feature/dashboard/b2b-dashboard/b2b-dashboard.module';
import { D2CDashboardModule } from '@demand-forecast/feature/dashboard/d2c-dashboard/d2c-dashboard.module';
import { WESDashboardModule } from '@demand-forecast/feature/dashboard/wes-dashboard/wes-dashboard.module';
import { EventsModule } from '@demand-forecast/feature/events/events.module';
import { B2BForecastCsvAdjustModule } from '@demand-forecast/feature/forecast-bulk-adjustment/b2b-forecast-csv-adjust/b2b-forecast-csv-adjust.module';
import { D2CForecastBulkAdjustmentRangeModule } from '@demand-forecast/feature/forecast-bulk-adjustment/d2c-forecast-bulk-adjustment-range/d2c-forecast-bulk-adjustment-range.module';
import { D2CForecastBulkAdjustmentModule } from '@demand-forecast/feature/forecast-bulk-adjustment/d2c-forecast-bulk-adjustment/d2c-forecast-bulk-adjustment.module';
import { WESForecastBulkAdjustmentModule } from '@demand-forecast/feature/forecast-bulk-adjustment/wes-forecast-bulk-adjustment/wes-forecast-bulk-adjustment.module';
import { RegionToWarehouseModule } from '@demand-forecast/feature/region-to-warehouse/region-to-warehouse.module';
import { ReportDefinitionsUploadModule } from '@demand-forecast/feature/report-definitions-upload/report-definitions-upload.module';
import { PermissionName } from '@demand-forecast/feature/role-form/domain';
import { RoleFormModule } from '@demand-forecast/feature/role-form/role-form.module';
import { BusinessDivisionModule } from '@demand-forecast/feature/settings/business-division/business-division.module';
import { RegionWarehouseWebsiteModule } from '@demand-forecast/feature/settings/region-warehouse-website/region-warehouse-website.module';
import { ReportDefinitionGroupsModule } from '@demand-forecast/feature/settings/report-definition-groups/report-definition-groups.module';
import { ReportsManagementModule } from '@demand-forecast/feature/settings/reports-management/reports-management.module';
import { RolesModule } from '@demand-forecast/feature/settings/roles/roles.module';
import { UserFormModule } from '@demand-forecast/feature/settings/users/user-form/user-form.module';
import { UsersModule } from '@demand-forecast/feature/settings/users/users/users.module';
import { VersionsModule } from '@demand-forecast/feature/settings/versions/versions.module';
import { VoyagerDomainModule } from '@demand-forecast/feature/settings/voyager-domain/voyager-domain.module';

const routes: Routes = [
  {
    path: THGRoutes.APP_ROUTES.ROOT,
    pathMatch: 'full',
    redirectTo: THGRoutes.APP_ROUTES.DASHBOARD_WES,
  },
  {
    path: THGRoutes.APP_ROUTES.DASHBOARD_WES,
    loadChildren: (): Promise<WESDashboardModule> =>
      import('@demand-forecast/feature/dashboard/wes-dashboard/wes-dashboard.module').then(
        (m) => m.WESDashboardModule,
      ),
  },
  {
    path: THGRoutes.APP_ROUTES.DASHBOARD_D2C,
    loadChildren: (): Promise<D2CDashboardModule> =>
      import('@demand-forecast/feature/dashboard/d2c-dashboard/d2c-dashboard.module').then(
        (m) => m.D2CDashboardModule,
      ),
  },
  {
    path: THGRoutes.APP_ROUTES.DASHBOARD_B2B,
    loadChildren: (): Promise<B2BDashboardModule> =>
      import('@demand-forecast/feature/dashboard/b2b-dashboard/b2b-dashboard.module').then(
        (m) => m.B2BDashboardModule,
      ),
  },
  {
    path: THGRoutes.ROLES_ROUTES.ROLES,
    loadChildren: (): Promise<RolesModule> =>
      import('@demand-forecast/feature/settings/roles/roles.module').then((m) => m.RolesModule),
    data: { permissions: { [PermissionName.ROLES]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.ROLES_ROUTES.ROLE,
    loadChildren: (): Promise<RoleFormModule> =>
      import('@demand-forecast/feature/role-form/role-form.module').then((m) => m.RoleFormModule),
    data: { permissions: { [PermissionName.ROLES]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'users',
    loadChildren: (): Promise<UsersModule> =>
      import('@demand-forecast/feature/settings/users/users/users.module').then((m) => m.UsersModule),
    data: { permissions: { [PermissionName.USERS]: PermissionLevel.READ_ONLY_BUSINESS_UNIT } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'users/:id',
    loadChildren: (): Promise<UserFormModule> =>
      import('@demand-forecast/feature/settings/users/user-form/user-form.module').then((m) => m.UserFormModule),
    data: { permissions: { [PermissionName.USERS]: PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'region-warehouse-website',
    loadChildren: (): Promise<RegionWarehouseWebsiteModule> =>
      import('@demand-forecast/feature/settings/region-warehouse-website/region-warehouse-website.module').then(
        (m) => m.RegionWarehouseWebsiteModule,
      ),
    data: {
      permissions: { [PermissionName.REGION_WAREHOUSE_WEBSITE]: PermissionLevel.FULL_ALL_BUSINESS_UNITS },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reports/management/upload',
    loadChildren: (): Promise<ReportDefinitionsUploadModule> =>
      import('@demand-forecast/feature/report-definitions-upload/report-definitions-upload.module').then(
        (m) => m.ReportDefinitionsUploadModule,
      ),
    data: { permissions: { [PermissionName.REPORTS_MANAGEMENT]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reports/management/:id',
    loadChildren: (): Promise<DashboardReportsModule> =>
      import('@demand-forecast/feature/dashboard-reports/dashboard-reports.module').then(
        (m) => m.DashboardReportsModule,
      ),
    data: {
      permissions: { [PermissionName.REPORTS_MANAGEMENT]: PermissionLevel.FULL_ALL_BUSINESS_UNITS },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reports/management',
    loadChildren: (): Promise<ReportsManagementModule> =>
      import('@demand-forecast/feature/settings/reports-management/reports-management.module').then(
        (m) => m.ReportsManagementModule,
      ),
    data: { permissions: { [PermissionName.REPORTS_MANAGEMENT]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reports/groups',
    loadChildren: (): Promise<ReportDefinitionGroupsModule> =>
      import('@demand-forecast/feature/settings/report-definition-groups/report-definition-groups.module').then(
        (m) => m.ReportDefinitionGroupsModule,
      ),
    data: { permissions: { [PermissionName.REPORTS_GROUP]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reports',
    loadChildren: (): Promise<DashboardReportsModule> =>
      import('@demand-forecast/feature/dashboard-reports/dashboard-reports.module').then(
        (m) => m.DashboardReportsModule,
      ),
    data: { permissions: { [PermissionName.REPORTS]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.WES_FORECAST_ROUTES.UPLOAD,
    loadChildren: (): Promise<ForecastCsvUploadModule> =>
      import('./feature/forecast-csv-upload/forecast-csv-upload.module').then((m) => m.ForecastCsvUploadModule),
    data: {
      permissions: { [PermissionName.WES_UPLOAD_AND_UPDATE_FORECAST]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.WES_FORECAST_ROUTES.FORECAST_ADJUSTMENT,
    loadChildren: (): Promise<WESForecastAdjustmentModule> =>
      import('./feature/wes-forecast-adjustment/wes-forecast-adjustment.module').then(
        (m) => m.WESForecastAdjustmentModule,
      ),
    data: {
      permissions: { [PermissionName.WES_VIEW_FORECAST_AND_EXPORT_DATA]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.WES_FORECAST_ROUTES.FORECAST_BULK_ADJUSTMENT,
    loadChildren: (): Promise<WESForecastBulkAdjustmentModule> =>
      import(
        '@demand-forecast/feature/forecast-bulk-adjustment/wes-forecast-bulk-adjustment/wes-forecast-bulk-adjustment.module'
      ).then((m) => m.WESForecastBulkAdjustmentModule),
    data: {
      permissions: { [PermissionName.WES_BULK_ADJUSTING_FORECAST]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.WES_FORECAST_ROUTES.BROWSE_DATA,
    loadChildren: (): Promise<WESBrowseDataModule> =>
      import('./feature/wes-browse-data/wes-browse-data.module').then((m) => m.WESBrowseDataModule),
    data: { permissions: { [PermissionName.WES_BROWSE_DATA]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.WES_FORECAST_ROUTES.ADJUSTMENT_ANALYSIS,
    loadChildren: (): Promise<AdjustmentAnalysisModule> =>
      import('./feature/adjustment-analysis/adjustment-analysis.module').then((m) => m.AdjustmentAnalysisModule),
    data: { permissions: { [PermissionName.WES_ADJUSTMENT_ANALYSIS]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.FORECAST_V2,
    loadChildren: (): Promise<D2cForecastV2Module> =>
      import('./feature/d2c-forecast-v2/d2c-forecast-v2.module').then((m) => m.D2cForecastV2Module),
    data: { permissions: { [PermissionName.D2C_BROWSE_DATA]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.BROWSE_DATA,
    loadChildren: (): Promise<D2CBrowseDataModule> =>
      import('./feature/d2c-browse-data/d2c-browse-data.module').then((m) => m.D2CBrowseDataModule),
    data: { permissions: { [PermissionName.D2C_BROWSE_DATA]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.FORECAST_ADJUSTMENT_WEEK,
    loadChildren: (): Promise<D2CForecastAdjustmentModule> =>
      import('@demand-forecast/feature/d2c-forecast-adjustment/d2c-forecast-adjustment.module').then(
        (m) => m.D2CForecastAdjustmentModule,
      ),
    data: {
      permissions: {
        [PermissionName.D2C_FORECAST_ADJUSTMENT_WEEK_AND_RANGE]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY,
      },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.FORECAST_ADJUSTMENT_RANGE,
    loadChildren: (): Promise<D2CForecastAdjustmentRangeModule> =>
      import('@demand-forecast/feature/d2c-forecast-adjustment-range/d2c-forecast-adjustment-range.module').then(
        (m) => m.D2CForecastAdjustmentRangeModule,
      ),
    data: {
      permissions: {
        [PermissionName.D2C_FORECAST_ADJUSTMENT_WEEK_AND_RANGE]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY,
      },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.FORECAST_BULK_ADJUSTMENT,
    loadChildren: (): Promise<D2CForecastBulkAdjustmentModule> =>
      import(
        '@demand-forecast/feature/forecast-bulk-adjustment/d2c-forecast-bulk-adjustment/d2c-forecast-bulk-adjustment.module'
      ).then((m) => m.D2CForecastBulkAdjustmentModule),
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.FORECAST_BULK_ADJUSTMENT_RANGE,
    loadChildren: (): Promise<D2CForecastBulkAdjustmentRangeModule> =>
      import(
        '@demand-forecast/feature/forecast-bulk-adjustment/d2c-forecast-bulk-adjustment-range/d2c-forecast-bulk-adjustment-range.module'
      ).then((m) => m.D2CForecastBulkAdjustmentRangeModule),
  },
  {
    path: THGRoutes.D2C_FORECAST_ROUTES.REGIONS_TO_WAREHOUSES,
    loadChildren: (): Promise<RegionToWarehouseModule> =>
      import('@demand-forecast/feature/region-to-warehouse/region-to-warehouse.module').then(
        (m) => m.RegionToWarehouseModule,
      ),
    data: {
      permissions: { [PermissionName.D2C_REGION_TO_WAREHOUSE_MAPPING]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY },
    },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.B2B_FORECAST_ROUTES.BROWSE_DATA,
    loadChildren: (): Promise<B2BBrowseDataModule> =>
      import('@demand-forecast/feature/b2b-browse-data/b2b-browse-data.module').then((m) => m.B2BBrowseDataModule),
    // data: { permissions: { [PermissionName.B2B_BROWSE_DATA]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    // canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.B2B_FORECAST_ROUTES.FORECAST_ADJUSTMENT,
    loadChildren: (): Promise<B2BForecastAdjustmentModule> =>
      import('@demand-forecast/feature/b2b-forecast-adjustment/b2b-forecast-adjustment.module').then(
        (m) => m.B2BForecastAdjustmentModule,
      ),
  },
  {
    path: THGRoutes.B2B_FORECAST_ROUTES.ADJUST,
    loadChildren: (): Promise<B2BForecastCsvAdjustModule> =>
      import(
        '@demand-forecast/feature/forecast-bulk-adjustment/b2b-forecast-csv-adjust/b2b-forecast-csv-adjust.module'
      ).then((m) => m.B2BForecastCsvAdjustModule),
  },
  {
    path: THGRoutes.VERSIONS_ROUTES.VERSIONS,
    loadChildren: (): Promise<VersionsModule> =>
      import('@demand-forecast/feature/settings/versions/versions.module').then((m) => m.VersionsModule),
    data: { permissions: { [PermissionName.CONFIG_VERSIONS]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.DIVISIONS_ROUTES.DIVISIONS,
    loadChildren: (): Promise<BusinessDivisionModule> =>
      import('@demand-forecast/feature/settings/business-division/business-division.module').then(
        (m) => m.BusinessDivisionModule,
      ),
    data: { permissions: { [PermissionName.CONFIG_DIVISIONS]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.VOYAGER_ROUTES.VOYAGER,
    loadChildren: (): Promise<VoyagerDomainModule> =>
      import('@demand-forecast/feature/settings/voyager-domain/voyager-domain.module').then(
        (m) => m.VoyagerDomainModule,
      ),
    data: { permissions: { [PermissionName.CONFIG_VOYAGER_SYNC]: PermissionLevel.FULL_BUSINESS_UNIT_ONLY } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.EVENTS_ROUTES.EVENTS,
    loadChildren: (): Promise<EventsModule> =>
      import('./feature/events/events.module').then((m) => m.EventsModule),
    data: { permissions: { [PermissionName.SALES_EVENTS]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.BUDGET_FORECAST_ROUTES.FREEZE,
    loadChildren: (): Promise<BudgetFreezeModule> =>
      import('@demand-forecast/feature/budget-freeze/budget-freeze.module').then((m) => m.BudgetFreezeModule),
    data: { permissions: { [PermissionName.BUDGET_FREEZE]: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: THGRoutes.APP_ROUTES.NOT_MATCHED,
    redirectTo: THGRoutes.APP_ROUTES.DASHBOARD_WES,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
