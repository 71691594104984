import { NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule, initialConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxMaskModule.forRoot(initialConfig),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // This is a little hacky - but it's basically modifying the DefaultValueAccessor to convert any blank strings to null.
    // This is useful as it globally handles this for us so that we don't run into errors when submitting forms.
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null): void => {
        fn(value === '' ? null : value);
      };
    };
  }
}
