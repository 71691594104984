<div class="wrapper">
  <thgc-left-sidebar
    [isOpen]="isLeftSidebarOpen"
    [navigationMenu]="navigationMenu"
    (closeSidebar)="onLeftSidebarToggle(false)"
  >
  </thgc-left-sidebar>

  <div class="content-page">
    <thgc-topbar
      [appTitle]="'appTitle' | translate"
      [user]="user$ | async"
      (logout)="onLogout()"
      (localeChange)="onLocaleChange($event)"
      (rightSidebarToggle)="onRightSidebarToggle(!isRightSidebarOpen)"
      (leftSidebarToggle)="onLeftSidebarToggle(!isLeftSidebarOpen)"
    >
    </thgc-topbar>

    <div class="page">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>

    <thgc-right-sidebar
      [isOpen]="isRightSidebarOpen"
      [themeMenu]="themeMenu"
      (closeSidebar)="onRightSidebarToggle(false)"
      (themeChange)="onThemeChange($event)"
    >
    </thgc-right-sidebar>

    <thgc-footer version="{{ 'version' | config }}"></thgc-footer>
  </div>
</div>
