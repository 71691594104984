import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PrimeNGConfig } from 'primeng/api';

import { UserService } from '@thg-procure-team/procure-common-ui/business/user';

@Component({
  selector: 'thg-root',
  templateUrl: `./app.component.html`,
})
export class AppComponent implements OnInit {
  public canDisplay$: Observable<boolean>;

  constructor(
    private primengConfig: PrimeNGConfig,
    private readonly userService: UserService,
    private readonly router: Router,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
  ) {}

  public ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.localStorage.setItem('thg_navigation_isExpanded', 'true');
    this.canDisplay$ = this.userService.user$.pipe(
      tap(() => this.checkPreviousRouterState()),
      map((user) => !!user),
    );
  }

  private checkPreviousRouterState(): void {
    const pathnameBeforeRedirect: string = this.localStorage.getItem('url_before_redirect');

    if (pathnameBeforeRedirect) {
      this.router.navigateByUrl(pathnameBeforeRedirect);
      this.localStorage.removeItem('url_before_redirect');
    }
  }
}
