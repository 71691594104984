import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { UserEntity, UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { NavigationMenuItem, ThemeMenuItem } from '@thg-procure-team/procure-common-ui/platform/layout';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { SubSink } from '@thg-procure-team/procure-common-ui/platform/utils';

import {
  THGB2BForecastPath,
  THGAppPath,
  THGConfigPath,
  THGD2CForecastPath,
  THGWESForecastPath,
  THGBudgetForecastPath,
  THGEventsPath,
} from '@demand-forecast/core/domain/routing.config';

@Component({
  selector: 'thg-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHomeComponent implements OnInit, OnDestroy {
  public isLeftSidebarOpen: boolean = false;
  public isRightSidebarOpen: boolean = false;
  public navigationMenu: NavigationMenuItem[] = [];
  public themeMenu: ThemeMenuItem[] = [];
  public user$: Observable<UserEntity>;

  private readonly subSink: SubSink = new SubSink();

  constructor(
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.user$ = this.userService.user$;
    this.setupMenuItems();
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public onLeftSidebarToggle(isLeftSidebarOpen: boolean): void {
    this.isLeftSidebarOpen = isLeftSidebarOpen;
  }

  public onRightSidebarToggle(isRightSidebarOpen: boolean): void {
    this.isRightSidebarOpen = isRightSidebarOpen;
  }

  public onLogout(): void {
    this.userService.logout();
  }

  public onLocaleChange(locale: string): void {
    this.userService.setLanguage(locale);
  }

  public onThemeChange(theme: string): void {
    this.userService.setTheme(theme);
  }

  private setupMenuItems(): void {
    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.themeMenu = [
        { label: this.thgcLocalizationService.translate('appSettings.darkThemeLabel'), key: 'dark' },
        { label: this.thgcLocalizationService.translate('appSettings.lightThemeLabel'), key: 'light' },
      ];

      this.navigationMenu = [
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoard'),
          icon: 'dripicons-meter',
          children: [
            {
              label: this.thgcLocalizationService.translate('shared.label.wesForecast'),
              link: THGAppPath.DASHBOARD_WES,
            },
            {
              label: this.thgcLocalizationService.translate('shared.label.d2cForecast'),
              link: THGAppPath.DASHBOARD_D2C,
            },
            {
              label: this.thgcLocalizationService.translate('shared.label.b2bForecast'),
              link: THGAppPath.DASHBOARD_B2B,
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoardReports'),
          icon: 'dripicons-meter',
          link: '/reports',
          exact: true,
        },
        {
          label: this.thgcLocalizationService.translate('shared.setupLabel'),
          icon: 'dripicons-gear',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.divisionsLabel'),
              link: THGConfigPath.DIVISIONS,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.usersLabel'),
              link: '/users',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.rolesLabel'),
              link: '/roles',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.reportsManagementLabel'),
              link: '/reports/management',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.reportDefinitionGroups'),
              link: '/reports/groups',
            },
            {
              label: this.thgcLocalizationService.translate('navigation.versionsLabel'),
              link: THGConfigPath.VERSIONS,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.voyagerSyncLabel'),
              link: THGConfigPath.VOYAGER,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.regionWarehouseWebsiteLabel'),
              link: THGConfigPath.REGION_WAREHOUSE_WEBSITE,
            },
          ],
        },
        { label: this.thgcLocalizationService.translate('shared.forecastsLabel'), isTitle: true },
        {
          label: this.thgcLocalizationService.translate('shared.label.wesForecast'),
          icon: 'uil-database',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.uploadForecastLabel'),
              link: THGWESForecastPath.UPLOAD,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.browseDataLabel'),
              link: THGWESForecastPath.BROWSE_DATA,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.forecastAdjustmentLabel'),
              link: THGWESForecastPath.FORECAST_ADJUSTMENT,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.adjustmentAnalysisLabel'),
              link: THGWESForecastPath.ADJUSTMENT_ANALYSIS,
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('shared.label.d2cForecast'),
          icon: 'uil-database',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.regionWarehouseMapping'),
              link: THGD2CForecastPath.REGIONS_TO_WAREHOUSES,
            },
            // {
            //   label: this.thgcLocalizationService.translate('navigation.browseDataLabel'),
            //   link: THGD2CForecastPath.BROWSE_DATA,
            // },
            {
              label: this.thgcLocalizationService.translate('shared.label.d2cV2Forecast'),
              link: THGD2CForecastPath.FORECAST_V2,
            },
            // {
            //   label: this.thgcLocalizationService.translate('navigation.forecastAdjustmentWeekLabel'),
            //   link: THGD2CForecastPath.FORECAST_ADJUSTMENT_WEEK,
            // },
            // {
            //   label: this.thgcLocalizationService.translate('navigation.forecastAdjustmentRangeLabel'),
            //   link: THGD2CForecastPath.FORECAST_ADJUSTMENT_RANGE,
            // },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('shared.label.b2bForecast'),
          icon: 'uil-database',
          children: [
            {
              label: this.thgcLocalizationService.translate('navigation.browseDataLabel'),
              link: THGB2BForecastPath.BROWSE_DATA,
            },
            {
              label: this.thgcLocalizationService.translate('navigation.forecastAdjustmentLabel'),
              link: THGB2BForecastPath.FORECAST_ADJUSTMENT,
            },
          ],
        },
        {
          label: this.thgcLocalizationService.translate('shared.eventsLabel'),
          isTitle: true,
        },
        {
          label: 'Events',
          icon: 'dripicons-list',
          link: THGEventsPath.EVENTS,
        },
        {
          label: 'Budget',
          isTitle: true,
        },
        {
          label: 'Budget Freeze',
          icon: 'dripicons-list',
          link: THGBudgetForecastPath.BUDGET_FREEZE,
        },
      ];

      this.changeDetectorRef.markForCheck();
    });
  }
}
