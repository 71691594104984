import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { KeycloakBearerInterceptor } from 'keycloak-angular';

import {
  BlobErrorTransformerInterceptor,
  ErrorHandlerInterceptor,
} from '@thg-procure-team/procure-common-ui/platform/http';

import { ApiPrefixInterceptor } from '@demand-forecast/core/http/apiPrefix.interceptor';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorTransformerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true },
  ],
  imports: [HttpClientModule],
  exports: [HttpClientModule],
})
export class HttpModule {}
