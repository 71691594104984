import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { switchMap, tap } from 'rxjs/operators';

import { UserEntity, UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { ThgcConfigModule, ThgcConfigService } from '@thg-procure-team/procure-common-ui/platform/config';
import { ThgcConfirmModule } from '@thg-procure-team/procure-common-ui/platform/confirm';
import { ThgcLayoutModule } from '@thg-procure-team/procure-common-ui/platform/layout';
import {
  ThgcLocalizationModule,
  ThgcLocalizationService,
} from '@thg-procure-team/procure-common-ui/platform/localization';
import { ThgcRouterResolveProgressbarModule } from '@thg-procure-team/procure-common-ui/platform/router-resolve-progressbar';
import { ThgcToastModule } from '@thg-procure-team/procure-common-ui/platform/toast';

import { HttpModule } from '@demand-forecast/core/http/http.module';
import { LayoutHomeComponent } from '@demand-forecast/core/layouts/layout-home/layout-home.component';
import { LayoutNotFoundComponent } from '@demand-forecast/core/layouts/layout-not-found/layout-not-found.component';

@NgModule({
  declarations: [LayoutHomeComponent, LayoutNotFoundComponent],
  imports: [
    CommonModule,
    HttpModule,
    ThgcLayoutModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    ThgcConfirmModule,
    ThgcToastModule,
    ThgcLocalizationModule,
    ThgcConfigModule,
    ThgcRouterResolveProgressbarModule,
  ],
  providers: [
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          userService: UserService,
          thgcConfigService: ThgcConfigService,
          thgcLocalizationService: ThgcLocalizationService,
        ) =>
        (): Promise<UserEntity> =>
          thgcConfigService
            .load()
            .pipe(
              tap((config) => (thgcLocalizationService.availableLocales = config.availableLanguages)),
              switchMap((config) => userService.login(config.keyCloakConfig)),
            )
            .toPromise(),
      multi: true,
      deps: [UserService, ThgcConfigService, ThgcLocalizationService],
    },
    {
      provide: BsDatepickerConfig,
      useFactory: (thgcLocalizationService: ThgcLocalizationService): BsDatepickerConfig => ({
        ...new BsDatepickerConfig(),
        showWeekNumbers: false,
        dateInputFormat: thgcLocalizationService.translate('shared.dateFormat').toUpperCase(),
      }),
      deps: [ThgcLocalizationService],
    },
  ],
  exports: [
    BrowserAnimationsModule,
    HttpModule,
    LayoutHomeComponent,
    ThgcToastModule,
    ModalModule,
    ThgcRouterResolveProgressbarModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentCoreModule: CoreModule,
    private readonly modalService: BsModalService,
  ) {
    if (parentCoreModule) {
      throw new Error(
        `${parentCoreModule.constructor.name} has already been loaded. Import this module in the AppModule only.`,
      );
    }
    this.modalService.config.class = 'modal-lg';
    this.modalService.config.ignoreBackdropClick = true;
  }
}
