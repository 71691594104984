export enum PermissionName {
  B2B_BROWSE_DATA = 'B2B Browse Data',
  B2B_ADJUSTING_FORECAST_MANUALLY = 'B2B Adjusting forecast manually',
  B2B_BULK_ADJUSTING_FORECAST = 'WES Bulk adjusting forecast',
  B2B_DASHBOARD = 'B2B Dashboard',
  CONFIG_DIVISIONS = 'Config divisions',
  CONFIG_EMAIL_NOTIFICATIONS = 'Config email notifications',
  CONFIG_VERSIONS = 'Config versions',
  CONFIG_VOYAGER_SYNC = 'Config voyager sync',
  D2C_V2 = 'D2C V2 Dev Access',
  D2C_BROWSE_DATA = 'D2C Browse data',
  D2C_DASHBOARD = 'D2C Dashboard',
  D2C_FORECAST_ADJUSTMENT_WEEK_AND_RANGE = 'D2C Forecast adjustment (week and range)',
  D2C_REGION_TO_WAREHOUSE_MAPPING = 'D2C Region to warehouse mapping',
  D2C_UPLOAD_AND_UPDATE_FORECAST = 'D2C Upload and update forecast',
  D2C_UPLOAD_AND_UPDATE_ACTUAL_DATA = 'D2C Upload and update actual data',
  REPORTS = 'Reports',
  REPORTS_GROUP = 'Reports group',
  REPORTS_MANAGEMENT = 'Reports management',
  ROLES = 'Roles',
  USERS = 'Users',
  REGION_WAREHOUSE_WEBSITE = 'Region Warehouse Website',
  WES_ADJUSTING_FORECAST_MANUALLY = 'WES Adjusting forecast manually',
  WES_ADJUSTMENT_ANALYSIS = 'WES Adjustment analysis',
  WES_BULK_ADJUSTING_FORECAST = 'WES Bulk adjusting forecast',
  WES_BROWSE_DATA = 'WES Browse data',
  WES_DASHBOARD = 'WES Dashboard',
  WES_UPLOAD_AND_UPDATE_FORECAST = 'WES Upload and update forecast',
  WES_VIEW_FORECAST_AND_EXPORT_DATA = 'WES View forecast and export data',
  SALES_EVENTS = 'Events',
  BUDGET_FREEZE = 'Budget Freeze',
  DEFAULT = 'Default',
}
