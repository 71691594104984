import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ThgcConfigService } from '@thg-procure-team/procure-common-ui/platform/config';

import { environment } from '../../../environments/environment';

const PROTOCOL_REG_EXP: RegExp = /^http[s]?:\/\//;

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private readonly thgcConfigService: ThgcConfigService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoredUrls = [
      './assets',
      this.thgcConfigService.get('api.baseUrl'),
      ...this.thgcConfigService.get('api.featuresUrl', []),
      this.thgcConfigService.get('api.userBaseUrl', ''),
      ...this.thgcConfigService.get('api.ignoredUrls', []),
    ].filter(Boolean);

    const isAbsoluteUrl = PROTOCOL_REG_EXP.test(req.url);
    const isIgnoredUrl = ignoredUrls.some((item) => req.url.indexOf(item) > -1);
    const url = isAbsoluteUrl || isIgnoredUrl ? req.url : environment.demandServiceUrl + req.url;

    return next.handle(req.clone({ url }));
  }
}
